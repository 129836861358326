import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";

import Layout from "../components/Layout";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { theme } from "../theme/theme";
import { Image } from "../components/atoms/Image";
import { Heading } from "../components/atoms/Heading";
import { Text } from "../components/atoms/Text";

export const DuurzaamPageTemplate = () => (
  <Container>
    <Row align="start" style={{minHeight: '50vh', marginTop: theme.space['10']}}>
      <Col xs={12} md={6}>
        <Image src={`${withPrefix('/')}img/fsc-image.jpg`} alt="Wildwood bomen" />
      </Col>
      <Col xs={12} md={4} offset={{md: 1}}>
        <Heading as="h1" variant="heading1">Lief voor mens en natuur</Heading>
        <Text>Wildwood is hét duurzame alternatief voor plastic kunstbomen en wegwerp-sparren. Er zijn geen fossiele grondstoffen gebruikt voor een Wildwood. Het is een houten boom die het broeikasgas CO2 juist opslaat. Omdat de boom jaren bij je blijft, ontlast je daarmee het milieu.</Text>

        <Heading as="h2" variant="heading2" style={{marginTop: theme.space['5']}}>Productie</Heading>
        <Text>Het hout waarmee we een Wildwood maken is geproduceerd volgens de normen van het keurmerk FSC. De productie en de oogst van FSC-goedgekeurd hout vindt op zo’n manier plaats dat moeder natuur er nooit onder lijdt.</Text>

        <Heading as="h2" variant="heading2" style={{marginTop: theme.space['5']}}>Over het FSC-keurmerk</Heading>
        <Text>
            De Forest Stewardship Council (FSC) is een internationale organisatie die zich inzet voor verantwoord bosbeheer wereldwijd. Ontbossing heeft een grote invloed op de opwarming van de aarde. FSC zet zich in voor herbebossing. Houtproducenten ontvangen het FSC keurmerk wanneer zij hun hout zo oogsten dat het bos waarin die bomen staan blijft bestaan. Op die manier worden ook de planten en dieren die erin leven beschermd.
        </Text>

        <Heading as="h2" variant="heading2" style={{marginTop: theme.space['5']}}>Sociaal werk</Heading>
        <Text>Wildwood bomen worden in opdracht vervaardigd door sociale werkplaatsen. Dat doen we omdat we het belangrijk vinden dat ook onze kwetsbare medemens zinvol werk kan doen.</Text>
      </Col>
    </Row>



  </Container>
);

DuurzaamPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const DuurzaamPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <DuurzaamPageTemplate
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

DuurzaamPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DuurzaamPage

export const DuurzaamPageQuery = graphql`
  query DuurzaamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
